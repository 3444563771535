<template>
  <div class="h-screen">
    <!-- Register actions -->
    <div class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-20">
        <div class="absolute top-5 right-5">
          <SelectLangs />
        </div>

        <div class="w-full mx-auto sm:max-w-md mb-7">
          <Brand size="normal" />
        </div>

        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <div v-if="!form.successful">
            <!-- Form -->
            <Card>
              <template #title>
                <div class="mx-6 pt-4">
                  <!-- Form title -->
                  <h2 class="text-xl font-semibold">
                    {{ $t("register.title") }}
                  </h2>
                </div>
              </template>
              <template #content>
                <div class="flex flex-col w-full">
                  <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
                    <!-- Email field -->
                    <div class="form-group">
                      <label for="email" class="form-label">{{ $t("email") }} <LabelRequired /></label>
                      <InputText v-model="form.email" id="email" name="email" type="email" class="p-inputtext-sm" autofocus />
                      <HasError :form="form" field="email" />
                    </div>

                    <!-- Name field -->
                    <div class="form-group">
                      <label for="name" class="form-label">{{ $t("name") }} <LabelRequired /></label>
                      <InputText v-model="form.name" id="name" name="name" type="text" class="p-inputtext-sm" autofocus />
                      <HasError :form="form" field="name" />
                    </div>

                    <!-- Password field -->
                    <div class="form-group">
                      <label for="password" class="form-label">{{ $t("password") }} <LabelRequired /></label>
                      <InputText v-model="form.password" id="password" name="password" type="password" class="p-inputtext-sm" />
                      <HasError :form="form" field="password" />
                    </div>

                    <!-- Password confirmation field -->
                    <div class="form-group">
                      <label for="password_confirmation" class="form-label">{{ $t("password_confirmation") }} <LabelRequired /></label>
                      <InputText v-model="form.password_confirmation" id="password_confirmation" name="password_confirmation" type="password" class="p-inputtext-sm" />
                      <HasError :form="form" field="password_confirmation" />
                    </div>

                    <!-- Form submit button -->
                    <Button type="submit" :label="$t('register.register')" :loading="form.busy" iconPos="right" class="p-button-primary p-button-lg shadow w-full" />

                    <!-- Form error general -->
                    <HasError :form="form" field="error" />
                  </form>

                  <Divider align="center">
                    <span>{{ $t("or_continue") }}</span>
                  </Divider>

                  <!-- Social register buttons -->
                  <SocialRegister />
                </div>
              </template>
            </Card>
          </div>
          <div v-else>
            <!-- Form success -->
            <Card>
              <template #content>
                <div class="flex-col-center mt-3 mb-6">
                  <Icon icon="fxemoji:sportsmedal" class="w-32 h-32 animate__animated animate__repeat-2 animate__swing" />
                </div>

                <h2 class="text-2xl font-semibold mb-3 text-center">{{ $t("register.congratulationsPart1") }}</h2>
                <h2 class="text-2xl font-semibold mb-3 text-center">{{ $t("register.congratulationsPart2") }}</h2>
                <p class="text-sm font-normal text-gray-500 mb-3 text-center">{{ $t("register.lastStepPart1", { email: form.email }) }}</p>
                <p class="text-sm font-normal text-gray-500 mb-3 text-center">{{ $t("register.lastStepPart2") }}</p>
                <div class="py-3">
                  <Message severity="info" class="ring-1 ring-blue-200" :closable="false">{{ $t("register.lastStepPart3") }}</Message>
                </div>

                <!-- Resend email confirmation -->
                <Button @click="sendEmailConfirmation" type="button" :label="$t('verification.resendEmailConfirmation')" :loading="formEmailVerification.busy && emailSents > 1" iconPos="right" class="p-button-primary shadow w-full" />

                <!-- Form success message -->
                <div v-if="formEmailVerification.recentlySuccessful && emailSents > 1" class="mt-3">
                  <Message severity="success" :closable="false">{{ $t("verification.emailConfirmationResent") }}</Message>
                </div>
              </template>
            </Card>
          </div>
        </div>

        <!-- Already have an account!, go to login -->
        <div class="flex-col-center mt-3">
          <router-link :to="{ name: 'Login' }">
            <Button :label="$t('already_have_an_account')" class="p-button-secondary p-button-text" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Framework components
import { Icon } from "@iconify/vue"
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Divider from "primevue/divider"
import Message from "primevue/message"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"
// App components
import Brand from "@/components/Brand"
import SocialRegister from "@/components/Register/SocialRegister"
import SelectLangs from "@/components/SelectLangs"
import LabelRequired from "@/components/LabelRequired"

export default {
  name: "Register",
  components: {
    // Framework components
    Icon,
    Card,
    Button,
    InputText,
    Divider,
    Message,
    HasError,
    // App components
    Brand,
    SocialRegister,
    SelectLangs,
    LabelRequired
  },

  data() {
    return {
      user: {},
      emailSents: 0,
      form: new FormClient({
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        config: {
          language: this.$store.state.Langs.selected?.lang
        }
      }),
      formEmailVerification: new FormClient({})
    }
  },

  methods: {
    async submit() {
      // Register
      const response = await this.form.post(ApiRoute.register.register)
      this.user = response.data.data

      // Send confirmation email
      this.sendEmailConfirmation()
    },

    sendEmailConfirmation() {
      this.formEmailVerification.get(ApiRoute.register.confirmation + this.user.id)
      this.emailSents++
    }
  }
}
</script>
